import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  GetStarted,
  Features,
  QuickStart,
  Services,
  Benefits,
  Hero,
  HappyCustomers,
  WhatIsERC
} from './components';

const IndexView = () => {
  const theme = useTheme();
  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main>
        <Container>
          <Hero />
        </Container>
        <Container paddingY={'0 !important'}>
          <Divider/>
        </Container>
        <Container>
          <HappyCustomers />
        </Container>
        <Container paddingY={'0 !important'}>
          <Divider/>
        </Container>
        <Container>
          <WhatIsERC/>
        </Container>
        <Container paddingY={'0 !important'}>
          <Divider/>
        </Container>
        <Container>
          <Services />
        </Container>
      </Main>
    </Box>
  );
};

export default IndexView;
