/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const mock = [
  {
    title: "ERC is a Payroll Tax Credit",
    subtitle:
      "ERC is a payroll tax credit that almost every business qualifies for given the new IRS rules under the CARES Act.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    title: 'How do I qualify?',
    subtitle:
      'There are two governing factors:',
    multipleFactors: [
      'You had a significant decline in gross receipts OR',
      'You were subject to a full or partial suspension of operations (i.e., operating at a reduced capacity due to a mandate)'
    ],
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
        />
      </svg>
    ),
  },
  {
    title: 'I was classified as an essential business. Do I still qualify?',
    subtitle:
      'Yes, essential businesses qualify for this tax credit.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
    ),
  },
  {
    title: 'I received PPP loans and they were forgiven. Do I still qualify?',
    subtitle:
      `Yes, you still qualify for the credit. However, the amount of PPP monies spent on payroll costs 
      will reduce the wages that would have otherwise qualified for the credit.`,
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    ),
  },
  {
    title: "My accountant told me I do not qualify. Why was I told this?",
    subtitle:
      `Your accountant has your best interest at heart. Initially, very few businesses qualified for this credit.
      Recently, the IRS changed the rules. Under the new rules, almost every small to medium sized business qualifies.`,
    link: {
      title: 'New IRS Rules',
      href: 'https://www.irs.gov/newsroom/new-law-extends-covid-tax-credit-for-employers-who-keep-workers-on-payroll'
    },
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    ),
  },
  {
    title: 'I want more detailed information about this credit.',
    subtitle:
      'We have made understanding this credit very simple.',
    link: {
      title: 'Find out more',
      href: 'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Ffiles%2FEmployee%20Retention%20Tax%20Credit.pdf?alt=media&token=59e04bd0-98de-4006-b16e-909d0ac36f05'
    },
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    ),
  },
];

const WhatIsERC = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography
          variant="h4"
          color="text.primary"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          What Is The Employee Retention Credit?
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={Card}
              padding={4}
              borderRadius={4}
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  component={Avatar}
                  width={50}
                  height={50}
                  marginBottom={2}
                  bgcolor={theme.palette.primary.main}
                  color={theme.palette.background.paper}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
                <Typography color="text.secondary" component="div">
                  <ul>
                  {item.multipleFactors &&
                    item.multipleFactors.map((factor, j) => {
                      return (
                        <li key={j}>{factor}</li>
                      )
                    })
                  }
                  </ul>
                </Typography>
                  {item.link &&
                    <Button variant={'contained'} target={'more'} href={item.link.href}>{item.link.title}</Button>
                  }
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatIsERC;
