/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Card, CardContent, CardActions, Button } from '@mui/material';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import DangerousIcon from '@mui/icons-material/Dangerous';
const mock = [
  {
    title: 'System Built To Maximize Your Returns',
    subtitle:
      'Our entire system is designed to maximize your returns',
    icon: <MonetizationOnRoundedIcon />,
  },
  {
    title: 'Risk Free',
    subtitle:
      "We do not get paid unless you are",
    icon: <DangerousIcon />,
  },
  {
    title: 'Fast & Easy Returns',
    subtitle:
      'Find out how much you are owed within 48 hours with little effort on your part',
    icon: <QuickreplyIcon />,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            How does it work?
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={'center'}
          >
            Working with ERT.credit is quick and painless
          </Typography>
        </Box>
        <Box maxWidth={600} component='div' sx={{
          margin: 'auto'
        }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14, fontWeight: 700 }} variant='h6' gutterBottom>
                Easy 8 Step Process
              </Typography>
              <Box maxWidth={600}>
                <img style={{ width: '100%' }}
                  src="https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2F8-step-process.jpg?alt=media&token=c8b3dc9d-4025-4e78-955a-afeb6983dda3" />
              </Box>
            </CardContent>
            <CardActions>
              <Box sx={{margin: 'auto'}}>
                <Button
                  variant={'contained'}
                  size="large"
                  href="/new-customer">Let's start here</Button>
              </Box>
            </CardActions>

          </Card>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
