/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const mock = [
  {
    title: 10,
    subtitle:
      'delivered to our customers to date.',
    suffix: '+',
  },
];

const HappyCustomers = () => {
  const dollars = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={4}>
            <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom>
              Join 100's of Happy Customers
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              Restaurants, bars, medical practices, non-profits,
              retailers, e-commerce stores, bakeries, chiropractors, hotels, motels
              and many other businesses have already claimed their Employee Retention Tax Credit (ERTC).
              <br />
              <br />
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" color={'primary'} gutterBottom>
              <VisibilitySensor
                onChange={(isVisible) => setViewPortVisibility(isVisible)}
                delayedCall
              >
                <CountUp
                  redraw={false}
                  end={viewPortEntered ? mock[0].title : 0}
                  start={0}
                  suffix={mock[0].suffix}
                  formattingFn={(number) => { return ("Over $" + number + "M+") }}
                />
              </VisibilitySensor>
            </Typography>
            <Typography color="text.secondary" variant="h4">
              {mock[0].subtitle}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'zoom-in'}
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={300}
              image="https://assets.maccarianagency.com/backgrounds/img4.jpg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HappyCustomers;
