import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const images = [
  {
    group: [
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg1.jpg?alt=media&token=fed7f1d8-a118-4a00-ac7c-b4fe46438052',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img2--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg10.jpg?alt=media&token=f1f7e6d2-79e9-446e-b786-94145177522c',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img3--dark.png',
      },
    ],
  },
  {
    group: [
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg2.jpg?alt=media&token=19b7d610-d9ab-48c4-8e33-d9b88994e343',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img13--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg3.jpg?alt=media&token=d02e7d50-f56c-4407-afb9-bf364c65b98e',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img10--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg5.jpg?alt=media&token=f9a0795d-fe5a-4e32-a0e7-609a4cdc07ac',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img9--dark.png',
      },
    ],
  },
  {
    group: [
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg6.jpg?alt=media&token=0bbc142f-3509-46bc-b99c-0d12e18e2561',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img6--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg7.jpg?alt=media&token=8e4a5a10-29a4-4489-a4ec-84c5c56ffa45',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img24--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg8.jpg?alt=media&token=1be50f62-91bb-4064-a161-9ea2e55fd5c2',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img17--dark.png',
      },
      {
        cover:
          'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fimg1.jpg?alt=media&token=fed7f1d8-a118-4a00-ac7c-b4fe46438052',
        coverDark:
          'https://assets.maccarianagency.com/screenshots/the-front/img12--dark.png',
      },
    ],
  },
];

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box paddingY={{ xs: 0, sm: '1rem', md: '1rem' }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: '50%' }}>
            <Typography
              variant="h3"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Get Your
              <br />
              Employee Retention Credit Today!{' '}
              <Typography
                color={'primary'}
                component={'span'}
                variant={'inherit'}
              >
                <Typed
                  strings={['Up To $33,000']}
                  typeSpeed={80}
                  loop={false}
                />
              </Typography>
              <br />
              Per Employee!
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              If your business or non-profit has fewer than 500 employees and you had to shut down or 
              adhere to a capacity restriction during any part of the Covid-19 pandemic, you qualify 
              for the Employee Retention Tax Credit (ERTC) from the Internal Revenue Service (IRS).
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              marginTop={4}
            >
              <Button
                component={'a'}
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                href={'/new-customer'}
              >
                Find Out How Much You Are Owed
              </Button>
              <Box
                marginTop={{ xs: 2, sm: 0 }}
                marginLeft={{ sm: 2 }}
                width={{ xs: '100%', md: 'auto' }}
              >
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            transform: 'rotate(-20deg)',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box
            display={'flex'}
            width={'50rem'}
            left={'50%'}
            top={0}
            position={'absolute'}
            sx={{ transform: 'translate3d(20%, -50%, 0)' }}
          >
            {images.map((item, i) => (
              <Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                {item.group.map((g, j) => (
                  <Box
                    key={j}
                    padding={1}
                    bgcolor={'background.paper'}
                    borderRadius={3}
                    boxShadow={3}
                    marginTop={2}
                  >
                    <Box
                      component={LazyLoadImage}
                      effect="blur"
                      src={
                        theme.palette.mode === 'dark' ? g.coverDark : g.cover
                      }
                      height={1}
                      width={1}
                      maxWidth={320}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
